import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import store from './store'

Vue.use(Vant);
import router from "../router"
import App from './App.vue'
Vue.config.productionTip = false
//路由验证
router.beforeEach((to, from, next)=>{
  document.title = ('title' in to.meta) && to.meta.title ? to.meta.title : '云卡官网';
  next()

})
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
