<template>
    <div class="footer-container">
        <div class="footer-bg">
            <img class="footer-bg-img" src="./images/bg_footer.png" alt="">
            <div class="footer-content-box">
                <div class="footer-content">
                    <div class="title">
                        <div>加盟合作</div>
                        <div class="line"></div>
                    </div>
                    <div class="content">
                        诚邀优质的商用车维修服务企业、配件经销企业加盟云卡维修连锁服务平台!
                        三级服务体系：旗舰店、服务店、快保店
                        九大平台支持：品牌、品系、系统、客源、技术、金融、信誉、人才
                    </div>
                    <div class="title">
                        <div>联系我们</div>
                        <div class="line"></div>
                    </div>
                    <div class="content">
                        <div class="con-text">地址：北京市朝阳区酒仙桥路14号兆维工业园C3楼2层</div>
                        <div class="con-text">电话：010-86460909</div>
                        <div class="con-text">邮箱：yunka@yuntruck.com</div>
                        <div class="con-text">官网：www.yuntruck.com</div>
                    </div>
                </div>
                <div class="footer-qrcode">
                    <div class="qrcode-img">
                        <div class="img-box"><img class="image" src="./images/qr_truck.jpg" alt=""></div>
                        <div class="img-text">云卡卡车维修连锁</div>
                    </div>
                    <div class="qrcode-img" style="margin-left: 1.83rem">
                        <div class="img-box"><img class="image" src="./images/qr_captain.jpg" alt=""></div>
                        <div class="img-text">云卡车队管理</div>
                    </div>
                </div>
            </div>
            <div class="footer-record">
                <div class="line"></div>
                <div class="record-content">
                <div class="text">Copyright © 2017 -2022 云卡. All rights reserved.</div>
                <div class="text">京ICP备17027411号｜商务合作邮箱 yunka@yuntruck.com</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped lang="less">
    .footer-container{
    .footer-bg {
        position: relative;
        width: 100%;
        .footer-bg-img {
            height: 46rem;
            width: 31.25rem;
        }
        .footer-content-box {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            .footer-content {
                padding: 1.58rem;
                .title {
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                .line {
                    margin: 0.42rem 0;
                    width: 3.33rem;
                    height: 0.17rem;
                    background-color: #CCCCCC;
                }

                .content {
                    font-size: 1.08rem;
                    margin-bottom: 1.5rem;
                    line-height: 1.8rem;
                    .con-text {
                        margin-top: 0.5rem;
                    }
                }
            }

            .footer-qrcode {
                padding: 0 1.58rem 1.58rem 1.58rem;
                display: flex;
                .qrcode-img {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .img-box {
                        display: flex;

                        .image {
                            width: 13.17rem;
                            height: 13.17rem;
                        }
                    }

                    .img-text {
                        margin-top: 0.5rem;
                        font-size: 1.25rem;
                        color: #666666;
                        font-weight: bold
                    }

                }
            }
        }
        .footer-record{
            .line{
                width: 31.25rem;
                height:0.42rem;
                background-color: #F08343;
                margin-top: -0.5rem;
            }
            .record-content{
                padding: 1.58rem 0 1.67rem 0;
                width: 100%;
                background-color: #161616;
                .text{
                    text-align: center;
                    color:#666666;
                    font-size: 1rem;
                }
            }
        }
    }}

</style>
