<template>
    <div>
        <div>
            <!--        <div class="container">-->
            <!--            <div class="menu-wrap">-->
            <!--                <nav class="menu">-->
            <!--                    <div class="icon-list">-->
            <!--                        <a href="#"><i class="fa fa-fw fa-star-o"></i><span>首页</span></a>-->
            <!--                        <a href="#"><i class="fa fa-fw fa-bell-o"></i><span>云卡联盟</span></a>-->
            <!--                        <a href="#"><i class="fa fa-fw fa-envelope-o"></i><span>云卡系统</span></a>-->
            <!--                        <a href="#"><i class="fa fa-fw fa-comment-o"></i><span>加盟合作</span></a>-->
            <!--                        <a href="#"><i class="fa fa-fw fa-bar-chart-o"></i><span>招聘英才</span></a>-->
            <!--                        <a href="#"><i class="fa fa-fw fa-newspaper-o"></i><span>关于我们</span></a>-->
            <!--                    </div>-->
            <!--                </nav>-->
            <!--                <button class="close-button" id="close-button">关闭导航</button>-->
            <!--                <div class="morph-shape" id="morph-shape" data-morph-open="M-1,0h101c0,0,0-1,0,395c0,404,0,405,0,405H-1V0z">-->
            <!--                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 800" preserveAspectRatio="none">-->
            <!--                        <path d="M-1,0h101c0,0-97.833,153.603-97.833,396.167C2.167,627.579,100,800,100,800H-1V0z"/>-->
            <!--                    </svg>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <button class="menu-button" id="open-button">导航按钮</button>-->
            <!--            <div class="content-wrap">-->
            <!--                <div class="content">-->
            <!--                    <header class="codrops-header">-->
            <!--                    </header>-->
            <!--                </div>-->
            <!--            </div>&lt;!&ndash; /content-wrap &ndash;&gt;-->
            <!--        </div>-->
        </div>
        <div class="layout-head">
            <div class="img-box">
                <img class="img" src="../../assets/logo.png" alt="">
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "index",
        methods: {
            goHome() {
                this.$router.push({name: 'home'})

            },
            goAbout() {
                this.$router.replace({
                    path: '/about'
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .layout-head {
        margin:1rem 0;
        .img-box {
            display: flex;
            align-items: center;
            justify-content: center;
            .img {
                width: 7.1rem;
                height: 2.33rem;
            }
        }
    }
</style>
