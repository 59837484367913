import {isEmpty} from '@/util/check'
import {getSecond} from '@/util/datetime'

//获取token信息
export function getToken(){
	let tokenCfg  = localStorage.tokenCfg;
	//token存在并且没有过期,直接返回
	if( !isEmpty(tokenCfg) ){
		tokenCfg = JSON.parse( tokenCfg )
		if( ('token' in tokenCfg) 
			&& ('expire' in tokenCfg) && tokenCfg['expire'] > getSecond() 
		){
			return tokenCfg;
		}
	}
	return {};
}

//保存登录token
export function setToken(info){
	let tokenCfg = {
			token: info.token,
			expire: getSecond() + info.expire,
			refresh: info.token
		}

	//保存token信息
	localStorage.setItem('tokenCfg', JSON.stringify(tokenCfg))
}

//清除token信息
export function clearToken(){
	localStorage.removeItem('tokenCfg')
}