/*时间处理函数*/
//moment可以参考 https://www.jianshu.com/p/9c10543420de
import moment from 'moment'

/**
 *获取周列表
 */
export function getWeekDay(weekday){
    let week = ['','周一','周二','周三','周四','周五','周六','周日'];
    return (weekday in week) ? week[weekday]: '';
}

/**
 *获取当前日期
 *@return Object
 */
 export function getToday(){
    let day = moment().format('YYYY-MM-DD');
    let today = day.split('-');
    let weekday = moment().weekday();
    return {
        year: parseInt( today[0] ),
        month: parseInt( today[1] ),
        dmonth: today[1],
        day: parseInt( today[2] ),
        dday: today[2],
        weekday: weekday == 0 ? 7 : weekday,
        endDay: moment().endOf('month').format('DD')
    }
 }

/**
 *获取当前日期信息
 *@return Object
 */
 export function getDayInfo(dt){
    let day = moment(dt).format('YYYY-MM-DD-HH-mm-ss');
    let days = day.split('-');
    let weekday = moment(dt).weekday();
    return {
        year: parseInt( days[0] ),
        month: parseInt( days[1] ),
        dmonth: days[1],
        day: parseInt( days[2] ),
        dday: days[2],
        dhour: days[3],
        hour: parseInt( days[3] ),
        dminute: days[4],
        minute: parseInt( days[4] ),
        dsecond: days[5],
        second: parseInt( days[5] ),
        weekday: weekday == 0 ? 7 : weekday,
        endDay: moment().endOf('month').format('DD')
    }
 }

/**
 *获取当前秒(单位:秒)
 *
 *@return int
 */
export function getSecond() {
    return parseInt(new Date().getTime() / 1000);
}

/**
 *获取当前毫秒
 *
 *@return int
 */
export function getMilliSecond() {
    return parseInt(new Date().getTime());
}

/**
 *时间转时间戳
 *@param dt 时间戳(单位:秒)
 */
export function timeToStamp(dt){
    return moment(dt).valueOf();
}

/**
 *时间戳转时间
 *
 *@param stamp 时间戳(单位:秒)
 */
export function stampToTime(stamp, format){
    format = format ? format : 'YYYY-MM-DD HH:mm:ss'
    return moment( stamp * 1000 ).format(format);
}

/**
 *获取本周时间
 */
export function getCurrentWeek(){
    let t     = getToday();
    let today = [t.year, t.dmonth, t.dday].join('-');
   
    let end   = addDateTime(today, 7 - t.weekday, 'day');
    let endDay= [end.year, end.dmonth, end.dday].join('-');

    let start = subDateTime(endDay, 6, 'day');
    let startDay = [start.year, start.dmonth, start.dday].join('-');

    return {
        start: startDay,
        end: endDay
    };
}

/**
 *获取本周日期列表(周一 ~ 周日)
 */
export function getCurrentWeekDays(){
    let t     = getToday();
    let today = [t.year, t.dmonth, t.dday].join('-');
   
    let end   = addDateTime(today, 7 - t.weekday, 'day');
    let endDay= [end.year, end.dmonth, end.dday].join('-');

    let start = subDateTime(endDay, 6, 'day');
    let startDay = [start.year, start.dmonth, start.dday].join('-');

    return getDateRange(startDay, endDay);
}

/**
 *获取本月时间
 */
export function getCurrentMonth(){
    return {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD')
    };
}

/**
 *获取月份最后一天
 */
export function getMonthLastDay(month){
    let day = moment(month).endOf('month').format('YYYY-MM-DD');
    let weekday= moment(day).weekday();
    let dayArr = day.split('-');
    return {
        year: parseInt(dayArr[0]),
        month: parseInt(dayArr[1]),
        dmonth: dayArr[1],
        day: parseInt(dayArr[2]),
        dday: dayArr[2],
        weekday: weekday == 0 ? 7 : weekday
    }
}


/**
 *获取本月日期列表
 */
export function getCurrentMonthDays(){
    let t     = getToday();
    let month = [t.year, t.dmonth].join('-');

    return getMonthDays(month);
}

/**
 *获取月份日期列表
 *
 *@param string month  月份
 *@return list
 */
 export function getMonthDays(month){
    //当月第一天
    let startDay = moment(month).startOf("month").format('YYYY-MM-DD');
    let endDay = moment(month).endOf("month").format('YYYY-MM-DD');
    let days = moment(month).daysInMonth();
    
    let monthDays = [];    
    for( let i=0; i<days; i++){
        let dayStr = moment(startDay).add(i, 'day').format('YYYY-MM-DD');
        let weekday= moment(dayStr).weekday();
        let dayArr = dayStr.split('-');
        let day = {
            year: parseInt(dayArr[0]),
            month: parseInt(dayArr[1]),
            dmonth: dayArr[1],
            day: parseInt(dayArr[2]),
            dday: dayArr[2],
            weekday: weekday == 0 ? 7 : weekday
        };
        monthDays.push( day );
    }
    return monthDays;
 }

/**
 *获取当前日期和时间
 *@param string format 格式:Y-m-d H:i:s
 *
 *@return String
 */
export function getDateTime(format) {
    if (!format) format = "Y-m-d H:i:s";
    let date = new Date();
    let dt = {
        Y: date.getFullYear(),
        y: date.getYear(),
        m:
            date.getMonth() < 9
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1,
        d: date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        H: date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        i: date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
        s: date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
    };

    for (var k in dt) {
        format = format.replace(k, dt[k]);
    }
    return format;
}

/**
 *格式化时间
 *@param string dt 日期时间:2019-10-01 00:00:00
 *@param string format 格式化: Y-m-d H:i:s
 *
 *@return String
 */
export function dateFormate(dt, format) {
    if (!dt) return '';
    if (!format) format = 'Y-m-d H:i:s';

    let dayTime = moment(dt).format('YYYY-MM-DD-HH-mm-ss');
    let dayArr  = dayTime.split('-');
    let week    = moment(dt).format('E');
    let weekday = ['日','一','二','三','四','五','六'];

    let dts = {
        Y: dayArr[0],
        m: dayArr[1],
        d: dayArr[2],
        H: dayArr[3],
        i: dayArr[4],
        s: dayArr[5],
        w: weekday[week],
    };

    for (var k in dts) {
        format = format.replace(k, dts[k]);
    }
    return format;
}

/**
 *格式日期
 *@param string dt 日期
 */
 export function dayFormatString(dt, format){
    if( !dt ) return '';
    

    let day = moment(dt).format('YYYY-MM-DD');
    let dayArr = day.split('-');

    let dts = {
        Y: dayArr[0],
        M: dayArr[1],
        m: parseInt(dayArr[1]),
        D: dayArr[2],
        d: parseInt(dayArr[2])
    };

    let today = getToday();
    if( today.year==dts.Y && today.dmonth==dts.M && today.dday == dts.D ){
        return '今天';
    }

    if( !format ) format = 'Y年m月d日';
    if( today.year != dayArr[0] ) format = 'Y.m.d'; 
    for (var k in dts) {
        format = format.replace(k, dts[k]);
    }

    return format;
 }

/**
*格式化时间段
*/
export function formateDays(start, end){
    if( start == '' && end == '' ) return '';
    start   = dayFormateString(start, 'Y年m月d日');
    end     = dayFormateString(end, 'Y年m月d日');
    
    let today = getToday();
    start   = start.replace( today.year+'年', '');
    end     = end.replace( today.year+'年', '');

    if( (start!='' && end=='') || (start == end) ){
        return start;
    }else{
        return start +' - ' + end;
    }
}

/**
 *根据开始时间和截止时间获取时间范围
 *@param string start 开始时间
 *@param string end 截止时间
 */
export function getDateRange(start, end){
    let startMill = moment(start).valueOf() ;
    let endMill = moment(end).valueOf();

    let num = 0;
    let result = [];
    for(startMill; startMill<=endMill; startMill){
        let dayStr = moment(startMill).format('YYYY-MM-DD');

        let dayArr = dayStr.split('-');
        let weekday= moment(startMill).format('E');
        let day = {
            year: parseInt(dayArr[0]),
            month: parseInt(dayArr[1]),
            dmonth: dayArr[1],
            day: parseInt(dayArr[2]),
            dday: dayArr[2],
            weekday: weekday == 0 ? 7 : parseInt(weekday),
        };
        result.push( day );

        startMill += 86400000;
        if( num > 1000 )break;
        num++;
    }
    return result;
}

/**
 *获取月份范围
 *@param string start 开始时间
 *@param string end  截止时间
 */
 export function getMonthRange(start, end){
    let startMill = moment(start).valueOf() ;
    let endMill = moment(end).valueOf();

    let months = [];
    let n = 100;        //最多100个月
    let i = 0;
    for(i; i<n; i++){
        let monthStr = moment( start ).add(i, 'month').format('YYYY-MM');

        startMill = moment(monthStr).valueOf();
        if( startMill > endMill) break;

        let monthArr = monthStr.split('-');
        let month = {
            year: parseInt( monthArr[0] ),
            month: parseInt( monthArr[1] ),
            dmonth: monthArr[1]
        };
        months.push( month );
    }
    return months;
 }

/**
 *获取两个日期的时间差
 *@param string start 开始时间
 *@param string end  截止时间
 *@param string type 单位类型: year:年 month:月 day:日 hour:小时 minute:分钟 second:秒 millisecond:毫秒
 */
 export function diffDateTime(start, end, unit){
    return moment(end).diff(moment(start), unit);
 }

/**
 *时间相加
 *@param string dt 时间
 *@param int num 累加数量
 *@param string unit 单位类型: year:年 month:月 day:日 hour:小时 minute:分钟 second:秒 millisecond:毫秒
 */
 export function addDateTime(dt, num, unit){
    let dayTime = moment(dt).add(num, unit).format('YYYY-MM-DD-HH-mm-ss');
    let dayArr  = dayTime.split('-');

    let weekday = moment( dayArr[0]+'-'+dayArr[1]+'-'+dayArr[2] ).weekday();
    return {
        year: parseInt(dayArr[0]),
        month: parseInt(dayArr[1]),
        dmonth: dayArr[1],
        day: parseInt(dayArr[2]),
        dday: dayArr[2],
        weekday: weekday == 0 ? 7 : weekday
    }
 }

/**
 *时间相减
 *@param string dt 时间
 *@param int num 数量
 *@param string unit 单位类型: year:年 month:月 day:日 hour:小时 minute:分钟 second:秒 millisecond:毫秒
 */
 export function subDateTime(dt, num, unit){
    let day = moment(dt).subtract(num, unit).format('YYYY-MM-DD-HH-mm-ss');
    let dayArr  = day.split('-');
    let weekday = moment( dayArr[0]+'-'+dayArr[1]+'-'+dayArr[2] ).weekday();
    return {
        year: parseInt(dayArr[0]),
        month: parseInt(dayArr[1]),
        dmonth: dayArr[1],
        day: parseInt(dayArr[2]),
        dday: dayArr[2],
        weekday: weekday == 0 ? 7 : weekday,
    }
 }





