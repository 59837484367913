/*免登陆设置*/
const authorization = [
	'/',
	'/login',
	'/forget',
	'/register'
];

/*接口免token*/
const untoken = [
	'/auth/login',
]

export{
	authorization,
	untoken
}