<template>
    <div class="join-container">
        <div class="head">
            <img class="head-img" src="./images/img_banner_join.jpg" alt="">
            <div class="head-con">
                <div class="head-title">
                    加盟合作
                </div>
                <div class="line-box">
                    <div class="line"></div>
                </div>
                <div class="head-content">
                    我们诚邀全国所有区域的优质的商用车维修服务企业，配件经销企业加盟云卡维修连锁服务平台，
                    共同为广大物流货运车辆提供质优、价廉、可靠、专业、高效的服务，同时致力于提升整个行业的服务水平，改善传统企业的经营状况，实现商用车维修行业互联网+的升级改造。
                </div>
                <div class="head-content">
                    我们将协同所有加盟合作伙伴共同打造云卡卡车维修连锁的平台品牌，大家共享平台联盟带来的聚合力量，
                    坚持品质+服务的品牌理念，把云卡平台优质、廉价、可靠、专业、高效的服务理念奉献给我们的客户。
                </div>
                <img class="head-con-img" src="./images/img_join.jpg" alt="">
            </div>

        </div>
        <div class="body">
            <div class="list-box">
                <img class="bg-img" src="./images/support_bg.jpg" alt="">
                <div class="body-list-box">
                    <div class="head-title">
                        云卡平台八大支持
                    </div>
                    <div class="line-box">
                        <div class="line"></div>
                    </div>
                    <div class="body-list">
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_01.png" alt="">
                            </div>
                            <div class="name">品牌</div>
                            <div class="intro">
                                享受云卡的品牌效应
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_02.png" alt="">
                            </div>
                            <div class="name">零件</div>
                            <div class="intro">
                                数千家零部件厂家直供配件
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_03.png" alt="">
                            </div>
                            <div class="name">系统</div>
                            <div class="intro">
                                云管理平台提升管理效率
                            </div>
                        </div>
                    </div>
                    <div class="body-list">
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_04.png" alt="">
                            </div>
                            <div class="name">客源</div>
                            <div class="intro">
                                线上线下集客资源
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_05.png" alt="">
                            </div>
                            <div class="name">技术</div>
                            <div class="intro">
                                完善的维修技术培训支持
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_06.png" alt="">
                            </div>
                            <div class="name">金融</div>
                            <div class="intro">
                                配件采购及门店经营的金融支持
                            </div>
                        </div>
                    </div>
                    <div class="body-list">
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_07.png" alt="">
                            </div>
                            <div class="name">信誉</div>
                            <div class="intro">
                                完善的客户服务跟踪分析体系
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="item-img">
                                <img class="img-box" src="./images/icon_support_08.png" alt="">
                            </div>
                            <div class="name">人才</div>
                            <div class="intro">
                                定向培养与择优并举提供后续人才保障
                            </div>
                        </div>
                        <div class="list-item">
                            <!--                    <div class="item-img">-->
                            <!--                        <img class="img-box" src="./images/icon_support_01.png" alt="">-->
                            <!--                    </div>-->
                            <!--                    <div class="name">品牌</div>-->
                            <!--                    <div class="intro">-->
                            <!--                        享受云卡的品牌效应-->
                            <!--                    </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="serve-item">
                <div class="head-title">
                    三级服务体系
                </div>
                <div class="line-box">
                    <div class="line"></div>
                </div>
                <div class="head-content">
                    我们将在全国区域建立旗舰店、服务店、快保店的三级服务体系
                </div>
                <div class="collapse">
                    <div class="expand-wrapper" v-if="index==2">
                        <div @click="changeIndex(1)">
                            <img class="serve-title-img" src="./images/img_title_qijiandian_active.png" alt="">
                        </div>
                        <div><img class="shop-img" src="./images/shop02.jpg" alt=""></div>
                        <div class="intro">
                            <div class="intro-title">面积</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">9000平方米以上，其中配件仓库300平米。</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">分布</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">地级以上城市/中心城市/物流集散地，服务半径：100公里</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">位置以及环境</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">4S店的建站标准，店面形象优秀，业内影响力大，客户服务环境好。</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">人力资源能力要求</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">良好的服务理念，经营者具备良好的品牌经营意识，并且清晰发展思路。
                            </div>
                            <div class="intro-content">
                                高级技师8人以上；
                            </div>
                            <div class="intro-content">
                                服务接待1人；
                            </div>
                            <div class="intro-content">
                                配件销售1人；
                            </div>
                        </div>
                        <div class="intro" style="display: flex">
                            <div>
                                <div class="intro-title">工位</div>
                                <div class="intro-line"></div>
                                <div class="intro-content">8个及以上</div>
                            </div>
                            <div style="margin-left:3rem;">
                                <div class="intro-title">营业额(万/年)</div>
                                <div class="intro-line"></div>
                                <div class="intro-content">2000万以上</div>
                            </div>
                        </div>
                    </div>
                    <div class="expand-wrapper" v-if="index==1">
                        <div @click="changeIndex(2)">
                            <img class="serve-title-img" src="./images/img_title_qijiandian_normal.png" alt="">
                        </div>
                    </div>
                    <div class="expand-wrapper" v-if="fuwuIndex==2">
                        <div @click="changefuwuIndex(1)">
                            <img class="serve-title-img" src="./images/img_title_fuwudian_active.png" alt="">
                        </div>
                        <div><img class="shop-img" src="./images/shop01.jpg" alt=""></div>
                        <div class="intro">
                            <div class="intro-title">面积</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">6000平方米以上 其中配件仓库100平米</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">分布</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">县级市/地级市/中心城市 物流主干道或物流集散地 服务半径：50公里</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">位置以及环境</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">店面形象好 服务环境好 交通便利</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">人力资源能力要求</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">良好的服务理念 经营者具备良好的品牌经营意识 有创建服务网络的意愿
                            </div>
                            <div class="intro-content">
                                高级技师5人以上
                            </div>
                            <div class="intro-content">
                                服务接待1人
                            </div>
                            <div class="intro-content">
                                配件销售1人
                            </div>
                        </div>
                        <div class="intro" style="display: flex">
                            <div>
                                <div class="intro-title">工位</div>
                                <div class="intro-line"></div>
                                <div class="intro-content">4个及以上</div>
                            </div>
                            <div style="margin-left:3rem;">
                                <div class="intro-title">营业额(万/年)</div>
                                <div class="intro-line"></div>
                                <div class="intro-content">400万以上</div>
                            </div>
                        </div>
                    </div>
                    <div class="expand-wrapper" v-if="fuwuIndex==1">
                        <div @click="changefuwuIndex(2)">
                            <img class="serve-title-img" src="./images/img_title_fuwudian_normal.png" alt="">
                        </div>
                    </div>
                    <div class="expand-wrapper" v-if="kuaiIndex==2">
                        <div @click="changekuaiIndex(1)">
                            <img class="serve-title-img" src="./images/img_title_kuaibaodian_active.png" alt="">
                        </div>
                        <div><img class="shop-img" src="./images/shop03.jpg" alt=""></div>

                        <div class="intro">
                            <div class="intro-title">面积</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">3000平方米以上</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">分布</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">县/县级市/地级市 /大中城市 同品牌间隔：2公里</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">位置以及环境</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">店面形象好 交通便利(主干道路边 /近小区/单位)</div>
                        </div>
                        <div class="intro">
                            <div class="intro-title">人力资源能力要求</div>
                            <div class="intro-line"></div>
                            <div class="intro-content">良好的服务理念 良好的品牌经营意识
                            </div>
                            <div class="intro-content">
                                高级技师2人以上
                            </div>
                            <div class="intro-content">
                                服务接待+配件销售1人
                            </div>

                        </div>
                        <div class="intro" style="display: flex">
                            <div>
                                <div class="intro-title">工位</div>
                                <div class="intro-line"></div>
                                <div class="intro-content">2个及以上</div>
                            </div>
                            <div style="margin-left:3rem;">
                                <div class="intro-title">营业额(万/年)</div>
                                <div class="intro-line"></div>
                                <div class="intro-content">300万以上</div>
                            </div>
                        </div>
                    </div>
                    <div class="expand-wrapper" v-if="kuaiIndex==1">
                        <div @click="changekuaiIndex(2)">
                            <img class="serve-title-img" src="./images/img_title_kuaibaodian_normal.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import {wxInitGet} from "../../interface/api/wxInit";

    export default {
        name: "index",
        data() {
            return {
                activeName: '1',
                index: 2,
                fuwuIndex: 1,
                kuaiIndex: 1
            };
        },
        created() {
            this.wxInit()
            //http://images.yuntruck.com/default/202303/1678439069355.png
        },
        methods: {
            wxInit() {
                const data = {
                    url: 'https://h5.www.yuntruck.com/join'
                }

                wxInitGet(data).then(res => {
                  let info = res.response
                    // 需要使用的JS接口列表
                    const wxJsApiList = [
                        'checkJsApi',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage'
                    ];

                    wx.config({
                        debug: false,
                        appId: info.appId,
                        timestamp: info.timestamp,
                        nonceStr: info.nonceStr,
                        signature: info.signature,
                        jsApiList: wxJsApiList
                    });

                    wx.error(function (res) {
                        //console.log('errInfo:',res)
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
                    });
                    let  infoUrl = `${window.location.protocol}//${window.location.host}/redirect.html?redirectUrl=${encodeURIComponent(info.url)}`;
                    var shareAppMessage = {
                        title: "云卡科技",
                        desc: "商用车维修连锁服务互联网平台",
                        link: infoUrl,
                        imgUrl: "http://images.yuntruck.com/default/202303/1678439100542.png",
                        success() {
                            //console.log("分享成功")
                        }
                    };

                    // eslint-disable-next-line no-undef
                    wx.ready(function () {
                        wx.checkJsApi({
                            jsApiList: wxJsApiList,
                            success(res) {
                                //console.log("checkJsApi: " + JSON.stringify(res));
                            }
                        });
                        wx.updateAppMessageShareData(shareAppMessage)
                        wx.updateTimelineShareData(shareAppMessage)
                    })

                }).catch((error => {
                    console.log('error:', error)
                }))

            },
            changeIndex(index) {
                this.index = index
                if (index == 2) {
                    this.fuwuIndex = 1
                    this.kuaiIndex = 1
                }
            },
            changefuwuIndex(index) {
                this.fuwuIndex = index
                if (index == 2) {
                    this.index = 1
                    this.kuaiIndex = 1
                }
            },
            changekuaiIndex(index) {
                this.kuaiIndex = index
                if (index == 2) {
                    this.index = 1
                    this.fuwuIndex = 1
                }
            }
        }


    }
</script>

<style scoped lang="less">
    .join-container {
        [class*=van-hairline]::after {

            border: none;

        }

        .head {
            .head-img {
                width: 31.25rem;
                height: 16.67rem;
            }

            .head-con {
                margin: 0 1.58rem;

                .head-title {
                    font-size: 1.5rem;
                    color: #555555;
                    font-weight: bold;
                    line-height: 2.2rem;
                    text-align: center;
                }

                .line-box {
                    margin-top: 0.75rem;
                    margin-bottom: 1.92rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line {
                        width: 3.33rem;
                        height: 0.17rem;
                        background-color: #CCCCCC;
                    }
                }

                .head-content {
                    font-size: 1.25rem;
                    color: #666666;
                    line-height: 2rem;
                }

                .head-con-img {
                    margin-top: 0.83rem;
                    margin-bottom: 1.23rem;
                    width: 27.92rem;
                    height: 14.94rem;
                }
            }
        }

        .body {
            .list-box {
                position: relative;

                .bg-img {
                    width: 100%;
                    height: 43.33rem;
                }

                .body-list-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;

                    .head-title {
                        margin-top: 1.33rem;
                        font-size: 1.5rem;
                        color: white;
                        font-weight: bold;
                        line-height: 2.2rem;
                        text-align: center;
                    }

                    .line-box {
                        margin-top: 0.75rem;
                        margin-bottom: 1.92rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .line {
                            width: 3.33rem;
                            height: 0.17rem;
                            background-color: white;
                        }
                    }

                    .body-list {
                        display: flex;
                        flex-wrap: wrap;

                        .list-item {
                            margin-left: 2rem;
                            width: 8rem;
                            margin-bottom: 2rem;

                            .item-img {
                                width: 8rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .img-box {
                                    width: 4.17rem;
                                    height: 4.17rem;
                                }
                            }

                            .name {
                                width: 100%;
                                text-align: center;
                                font-size: 1.33rem;
                                color: #FFFFFF;
                                margin: 0.5rem 0;
                            }

                            .intro {
                                margin-top: 0.5rem;
                                font-size: 1.17rem;
                                color: #FFFFFF;
                                line-height: 1.2rem;
                                text-align: center;
                            }
                        }
                    }
                }
            }

            .serve-item {
                margin: 0 1.58rem;

                .head-title {
                    font-size: 1.5rem;
                    color: #555555;
                    font-weight: bold;
                    line-height: 2.2rem;
                    text-align: center;
                }

                .collapse {
                    .expand-wrapper {
                        margin-bottom: 1.67rem;

                        .intro {
                            .intro-title {
                                font-size: 1.33rem;
                                color: #555555;
                                line-height: 2.2rem;
                                font-weight: bold;
                            }

                            .intro-line {
                                margin: 0.42rem 0;
                                background: #CCCCCC;
                                width: 3.33rem;
                                height: 0.17rem;
                            }

                            .intro-content {
                                font-size: 1.25rem;
                                color: #666666;
                                line-height: 2rem;
                            }

                        }
                    }

                    .serve-title-img {
                        width: 27.92rem;
                        height: 4.83rem;
                    }

                    .shop-img {
                        width: 27.87rem;
                        height: 19.17rem;
                    }
                }

                .line-box {
                    margin-top: 0.75rem;
                    margin-bottom: 1.92rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .line {
                        width: 3.33rem;
                        height: 0.17rem;
                        background-color: #CCCCCC;
                    }
                }

                .head-content {
                    font-size: 1.25rem;
                    color: #666666;
                    text-align: center;
                    line-height: 2rem;
                }


            }
        }
    }
</style>
