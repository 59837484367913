<template>
    <div>
        <div class="home-container">
            <div class="home-head">
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item><img class="swiper-img" src="././images/img_banner_index_01.jpg" alt="">
                        <div class="mask">
                            <div class="content">
                                <div>国内首家O2O卡车维修联盟互联网平台</div>
                                <div class="mask-btn-box" @click="goUnion">
                                    <div class="mask-btn">了解详情</div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item><img class="swiper-img" src="././images/img_banner_index_02.jpg" alt="">
                        <div class="mask">
                            <div class="content">
                                <div>国内首家O2O卡车维修联盟互联网平台</div>
                                <div class="mask-btn-box">
                                    <div class="mask-btn">了解详情</div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item><img class="swiper-img" src="././images/img_banner_index_03.jpg" alt="">
                        <div class="mask">
                            <div class="content">
                                <div>国内首家O2O卡车维修联盟互联网平台</div>
                                <div class="mask-btn-box">
                                    <div class="mask-btn">了解详情</div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item><img class="swiper-img" src="././images/img_banner_index_04.jpg" alt="">
                        <div class="mask">
                            <div class="content">
                                <div>国内首家O2O卡车维修联盟互联网平台</div>
                                <div class="mask-btn-box">
                                    <div class="mask-btn">了解详情</div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item><img class="swiper-img" src="././images/img_banner_index_05.jpg" alt="">
                        <div class="mask">
                            <div class="content">
                                <div>国内首家O2O卡车维修联盟互联网平台</div>
                                <div class="mask-btn-box">
                                    <div class="mask-btn">了解详情</div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="home-content">

                <div class="home-intro">
                    <div class="title">云卡联盟</div>
                    <div class="title">国内首家O2O卡车维修联盟互联网平台</div>
                    <div class="line"></div>
                    <div class="content">
                        通过整合线下门店形成连锁联盟服务，建立全国范围内的卡车维修服务体系，与传统商用车修理厂不同的是，我们拥有全品牌，全品类、全国覆盖的服务能力，为全国商用车客户提供统一价格 , 统一服务标准 ,
                        统一结算的维修服务保障。
                    </div>
                </div>
            </div>
            <div class="home-item-swiper">
                <van-swipe class="my-swipe" indicator-color="#F08343" @change="onChange">
                    <van-swipe-item>
                        <div class="item-top">
                            <img class="swiper-img" src="././images/service01.jpg" alt="">
                            <div class="mask">
                                <div class="content">
                                    <div class="title">全品牌卡车</div>
                                    <div class="mask-intro">
                                        我们网络可以服务所有市场上主流主机厂品牌的卡车，从国产车到进口车。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class="item-bottom">-->
                        <!--                            <img class="swiper-img" src="././images/service02.jpg" alt="">-->
                        <!--                            <div class="mask">-->
                        <!--                                <div class="content">-->
                        <!--                                    <div class="title">全品类配件</div>-->
                        <!--                                    <div class="mask-intro">-->
                        <!--                                        平台将联合各渠道配件厂家，经销商、中心库为我们加盟合作门店提供小到车辆日常保养件（机油，三滤，尿素）大到所有车型件产品供应从而保证为我们物流车辆提供高性价比的配件。-->
                        <!--                                    </div>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="item-bottom">
                            <img class="swiper-img" src="././images/service07.jpg" alt="">
                            <div class="mask">
                                <div class="content">
                                    <div class="title">全国异地索赔</div>
                                    <div class="mask-intro">
                                        我们将利用互联网技术手段为平台的客户网内更换的配件，提供全国范围的异地索赔服务，在配件质保期内无论您的车辆跑到哪里都能找到我们加盟合作门店完成索赔更换服务。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="item-top">
                            <img class="swiper-img" src="././images/service03.jpg" alt="">
                            <div class="mask">
                                <div class="content">
                                    <div class="title">全国覆盖</div>
                                    <div class="mask-intro">
                                        目前云卡维修联盟加盟合作门店已经超过1000家，已经覆盖全国24个省份地区，未来将发展全国5000家门店。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-bottom">
                            <img class="swiper-img" src="././images/service04.jpg" alt="">
                            <div class="mask">
                                <div class="content">
                                    <div class="title">统一价格</div>
                                    <div class="mask-intro">
                                        我们将实现云卡网络联盟内全国统一工时服务价格，统一配件价格，保证客户在我们任何一家门店享受同样品质的配件和服务价格。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="item-top">
                            <img class="swiper-img" src="././images/service05.jpg" alt="">
                            <div class="mask">
                                <div class="content">
                                    <div class="title">统一服务标准</div>
                                    <div class="mask-intro">
                                        我们将利用互联网技术和高科技手段建立我们统一的服务标准体系，保证所有客户在全国享受同样的高质量服务体验。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-bottom">
                            <img class="swiper-img" src="././images/service06.jpg" alt="">
                            <div class="mask">
                                <div class="content">
                                    <div class="title">统一结算体系</div>
                                    <div class="mask-intro">
                                        我们将利用更多线上支付方式建立统一结算体系，由我们云卡平台实现对所有客户在平台所发生的服务承担后期监督、审核甚至的索赔的责任。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-swipe-item>
                    <template #indicator>
                        <!--                        <div class="custom-indicator">{{ current + 1 }}/4</div>-->
                        <div class="van-swipe__indicators">
                            <div :class="[current==0? activeClass:'',indicator]"></div>
                            <div :class="[current==1? activeClass:'',indicator]"></div>
                            <div :class="[current==2? activeClass:'',indicator]"></div>
                        </div>
                    </template>
                </van-swipe>
            </div>
            <div class="home-global">
                <div class="global-box">
                    <img class="global-img" src="././images/img_map_index.jpg" alt="">
                    <div class="global_mask">
                        <div class="content">
                            <div class="title">目前云卡维修联盟加盟合作门店已经超过1000家,
                                已经覆盖全国24个省份地区，未来将发展全国5000家门店。
                            </div>
                            <div class="mask-intro">
                                <div class="intro-box">
                                    <div class="box-top">合作门店</div>
                                    <div class="box-bottom"><span class="bottom-text">1000</span>+</div>
                                </div>
                                <div class="intro-box">
                                    <div class="box-top">覆盖省份</div>
                                    <div class="box-bottom"><span class="bottom-text">24</span><span
                                            style="font-size: 1rem">个</span></div>
                                </div>
                                <div class="intro-box">
                                    <div class="box-top">未来发展门店</div>
                                    <div class="box-bottom"><span class="bottom-text">5000</span>+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {wxInitGet} from "../../interface/api/wxInit";

    export default {
        name: "index",
        data() {
            return {
                current: 0,
                activeClass: 'active',
                indicator: 'indicator',
                form: {
                    staffCode: '',
                }
            }
        },
        created() {
            this.wxInit()
        },
        methods: {
            wxInit() {
                const data = {
                    url: 'https://h5.www.yuntruck.com/home'
                }

                wxInitGet(data).then(res => {
                    // console.log(wx)
                    let info = res.response
                    // 需要使用的JS接口列表
                    wx.config({
                        debug: false,
                        appId: info.appId,
                        timestamp: info.timestamp,
                        nonceStr: info.nonceStr,
                        signature: info.signature,
                        jsApiList: ['updateAppMessageShareData','updateTimelineShareData']
                    });

                    wx.error(function (res) {
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
                    });
    let  infoUrl =  `${window.location.protocol}//${window.location.host}/redirect.html?redirectUrl=${encodeURIComponent(info.url)}`;
                    console.log(infoUrl)
                    var shareAppMessage = {
                        title: "云卡科技",
                        desc: "商用车维修连锁服务互联网平台",
                        link:infoUrl,
                        imgUrl: "http://images.yuntruck.com/default/202303/1678435624314.png",
                        success: function(){

                        }
                    };
                    wx.ready(function () {
                        wx.checkJsApi({
                            jsApiList:[ 'updateAppMessageShareData','updateTimelineShareData'],
                            success(res) {

                            }
                        });
                        wx.updateAppMessageShareData(shareAppMessage)
                         wx.updateTimelineShareData(shareAppMessage)
                    })

                }).catch((error => {
                    console.log('error:', error)
                }))

            },
            onChange(index) {
                this.current = index;
            },
            goUnion() {
                this.$router.push({
                    path: '/union'
                })
            },
        }
    }
</script>

<style scoped lang="less">



    .home-container {
        .home-head {
            .my-swipe .van-swipe-item {
                color: #fff;
                font-size: 20px;
                text-align: center;
            }

            .swiper-img {
                width: 100%;
                height: 40%;
                position: relative;
            }

            .mask {
                position: absolute;
                width: 100%;
                height: 15.17rem;
                background: rgba(0, 0, 0, .1);
                top: 0;
                left: 0;

                .content {
                    margin-top: 60px;
                    font-size: 1.5rem;
                }

                .mask-btn-box {
                    margin-top: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .mask-btn {
                    border-radius: 0.3rem;
                    background-color: #ef803e;
                    width: 10rem;
                    padding: 0.5rem;
                    font-size: 1.25rem;
                }
            }
        }

        .home-content {
            .home-intro {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #555555;
                }

                .line {
                    margin: 0.92rem 0;
                    width: 3.33rem;
                    height: 0.17rem;
                    background: #CCCCCC;
                    border-radius: 0.25rem;
                }

                .content {
                    margin: 0 1.75rem 2rem 1.75rem;
                    color: #666666;
                    font-size: 1.25rem;
                }
            }
        }

        .home-item-swiper {
            .my-swipe .van-swipe-item {
                color: #fff;
                font-size: 1.5rem;
                text-align: center;
                line-height: 1.8rem;
            }

            .van-swipe {
                padding: 2rem 0;
            }

            .active {
                background: #ef803e !important;
            }

            .indicator {
                margin-left: 0.5rem;
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 100%;
                background: rgba(102, 102, 102, 0.50);
                -webkit-transition: opacity .2s, background-color .2s;
                transition: opacity .2s, background-color .2s;
            }


            .item-top {
                position: relative;
                width: 100%;
                height: 16.67rem;

                .swiper-img {
                    width: 100%;
                    height: 16.67rem;
                }

                .mask {
                    position: absolute;
                    width: 100%;
                    height: 16.67rem;
                    background: rgba(0, 0, 0, .5);
                    top: 0;
                    left: 0;

                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        height: 14rem;
                        margin-top: 1.25rem;
                    }

                    .tilte {
                        font-size: 1.67rem;
                        font-weight: bold;
                    }

                    .mask-intro {
                        font-size: 1.08rem;
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .mask-btn {
                        border-radius: 0.3rem;
                        background-color: #ef803e;
                        width: 10rem;
                        padding: 0.5rem;
                        font-size: 1.25rem;
                    }
                }
            }

            .item-bottom {
                margin-top: 1rem;
                width: 100%;
                height: 16.67rem;
                position: relative;

                .swiper-img {
                    width: 100%;
                    height: 16.67rem;

                }

                .mask {
                    position: absolute;
                    width: 100%;
                    height: 16.67rem;
                    background: rgba(0, 0, 0, .5);
                    top: 0;
                    left: 0;

                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        height: 14rem;
                        margin-top: 1.25rem;
                    }

                    .tilte {
                        font-size: 1.67rem;
                        font-weight: bold;
                    }

                    .mask-intro {
                        font-size: 1.08rem;
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .mask-btn {
                        border-radius: 0.3rem;
                        background-color: #ef803e;
                        width: 10rem;
                        padding: 0.5rem;
                        font-size: 1.25rem;
                    }
                }
            }

        }

        .home-global {
            .global-box {
                width: 100%;
                position: relative;

                .global-img {
                    height: 33rem;
                    width: 100%;
                }

                .global_mask {
                    position: absolute;
                    top: 0;
                    left: 0;

                    .content {

                        padding: 0 1.75rem;
                        color: white;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        height: 25rem;
                        margin-top: 3rem;
                    }

                    .title {
                        font-size: 1.25rem;
                        color: #FFFFFF;
                        text-align: center;

                    }

                    .mask-intro {
                        font-size: 1.08rem;
                        margin-top: 1rem;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .intro-box {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;

                        .box-top {
                            font-size: 1.25rem;
                        }

                        .box-bottom {
                            font-weight: bold;
                            font-size: 1.67rem;

                            .bottom-text {
                                font-size: 3rem;
                            }
                        }
                    }

                }
            }
        }
    }
</style>
