import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter)
import Home from '@/pages/Home'
import System from '@/pages/System'
import Union from "@/pages/Union"
import About from "@/pages/About"
import Join from "@/pages/Join"

//重写repalce和push
let originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => {
        }, () => {
        })
    }
}


let originReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => {
        }, () => {
        })
    }
}
const router= new VueRouter({
    mode:'history',
    routes: [{
        path: '/home',
        name:'home',
        component: Home,
        meta: {
            show: true,
            title:'云卡科技-全国卡车维修联盟互联网平台'
           //  title:'云卡科技官网'
        }
    },
        //云卡系统
        {
            path: '/system',
            name:'system',
            component: System,
            meta: {
                show: true,
                title:'云卡系统'
                // title:'云卡科技官网'

            }
        },
        //云卡联盟
        {
            path: '/union',
            name:'union',
            component: Union,
            meta: {
                show: true,
                title:'云卡联盟'
               //  title:'云卡科技官网'

            }
        },
        {
            path: '/join',
            name:'join',
            component: Join,
            meta: {
                show: true,
                title:'加盟合作'
               //  title:'云卡科技官网'

            }
        },
        {
            path: '/about',
            name:'about',
            component: About,
            meta: {
                show: true,
                title:'关于云卡'
                // title:'云卡科技官网'

            }
        },
        //重定向，在项目跑起来的时候，访问“/”,立马让他重定向到首页
        {
            path:'/',
            redirect:'/home'
        },
    ]
})
export default router
