/*检测工具*/

/**
 *检测变量是否为空
 *
 *@param  mixed  val
 *@return Boolean
 *
 *@notes 以下数据都返回 true, 其余返回false
 *			null, undefined , 空字符 , [], {}
 */
export function isEmpty(val){

	if( typeof(val)  === 'object' ){
		for( var k in val){
			return false;
		}
		return true;
	}else if( val === null || val === undefined || val === ''){
		return true;
	}
	return false;
}


