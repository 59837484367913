import axios from 'axios'
import qs from 'qs'
import {interfaces} from '@/config/interface'
import {untoken} from '@/config/authorization'
import {getToken} from '@/util/token'
import {isEmpty} from '@/util/check'
import {getSecond} from '@/util/datetime'


/*创建axios实例，并设置基本属性*/
const service = axios.create({
  baseURL:interfaces.host,
  timeout: 5000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
})


/**
 *发送请求之前检测数据检测
 *
 *1.请求头添加token
 *2.如果接口是post，序列化数据
 */
service.interceptors.request.use(
	async(config) => {
		//设置token
		if( untoken.indexOf( config.url ) === -1 ){
			config.headers['token'] = await getApiToken()
		}
		
		// POST请求类型的发送数据进行序列化
		if (config.method === 'post') {
			config.data = qs.stringify(config.data)
		}
		return config
  },
  error => {
		Promise.reject(error)
  }
)

/*获取请求后的数据处理*/
service.interceptors.response.use(response => {
	if( response.status !== 200 ){
		return Promise.reject('Request Error!').catch((e)=>{})
	}
	const data = response.data
	// 接口中不含code || status字段时抛错，防止接口错误
	if(!data.hasOwnProperty('code') || !data.hasOwnProperty('status')) {
		return Promise.reject('Code Error!').catch((e)=>{})
	}

	//服务器业务错误
	const errCode = [3002];
	if( errCode.indexOf( data.code ) > -1 ){
		return Promise.reject('Server Error!').catch((e)=>{})
	}

	return data
  },
  error => {
	return Promise.reject(error).catch((e)=>{})
  }
)


/*获取接口Token*/
async function getApiToken(){
	let tokenCfg = getToken();
	if( !isEmpty(tokenCfg) ){
		return tokenCfg.token;
	}

	//重新刷新token
	let response = await resetToken( tokenCfg.refresh );
	if( response.status === 200 
		&& ('code' in response.data) && response.data.code === 2000 
		&& ('response' in response.data)
		&& ('token' in response.data.response)
	){
		let expire = response.data.response.expire ? response.data.response.expire : interfaces.expire;
		tokenCfg = {
			token: response.data.response.token,
			expire: getSecond()+expire,
			refresh: response.data.response.refresh
		}

		//保存token信息
		localStorage.setItem('tokenCfg', JSON.stringify(tokenCfg));
		return tokenCfg.token;
	}else{
		return null;
	}
}


/**
 *根据refreshToken 换新 token
 */
function resetToken(token){
	//请求数据
	try{
		return axios({
			method:'post',
			baseURL:interfaces.host,
			url:'/auth/refresh',
			data: qs.stringify({token:token}),
			timeout: 5000,
			headers:{
				'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
			}
		})
	}catch(err){
		return null;
	}
}

export default service;

